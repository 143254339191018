<template lang="html">
  <div>
    I am Edit task
  </div>
</template>
<script>
export default {
  name: 'Edit'
}
</script>
